<template>
    <main class="app-content">

        <slot />

    </main>
</template>

<script setup>



</script>

<style lang="scss" scoped>

.app-content {

    padding: 3rem 0 1rem 0;

}

</style>