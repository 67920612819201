<template>
    <footer class="app-footer">

        <p>Developed by Diego Marques - <a href="https://diegomarques.com.br/" title="diegomarques.com.br" target="_blank">diegomarques.com.br</a></p>

    </footer>
</template>

<style lang="scss" scoped>

.app-footer {

    border-top: 1px solid $borderColor;
    display: block;
    padding: 1rem 0;
    text-align: center;

    p {

        margin: 0;

    }

}

</style>