<template>
    <section class="content">

        <slot />

    </section>
</template>

<style lang="scss" scoped>

.content { margin-bottom: 2rem; }

</style>