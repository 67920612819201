<template>
    <div class="button-group">

        <slot />

    </div>
</template>

<style lang="scss" scoped>

.button-group {

    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

}

</style>